export const AzureProvider = "AzureAD";
export const CRM_API = "crmApi";
export const PROJECT_API = "projectApi";
export const SUGGEST_API = "suggestApi";
export const TODO_API = "todoApi";
export const TOPHAT_API = "tophatApi";
export const QUICKSIGHT_API = "quicksightApi";
export const CHECKLISTRECORD_API = "checklistRecordApi";
export const ALL_ITEMS = "All Items";
export const PAGE_SIZE =  20;
export const LEAD_SHORT_TO = 8;
export const LEAD_MEDIUM_TO = 22;

export const COST_SHORT_TO = 50;
export const COST_MEDIUM_TO = 150;
export const BUCKET_TOPHAT = 'uat-mvp-data-platform-fragmented-data-ingestions';

export const DATE_FORMAT = "DD/MM/YYYY";

export const MOCK_IMAGES = [
  "Public/test_uc2/images/image1.jpg",
  "Public/test_uc2/images/image2.png",
  "Public/test_uc2/images/image3.jpg",
  "Public/test_uc2/images/image4.png"
];
export const MOCK_DOCS = [
  "Public/test_uc2/documents/doc_test_1.pdf",
  "Public/test_uc2/documents/doc_test_2.pdf",
  "Public/test_uc2/documents/doc_test_3.pdf"
];

export const materialTypes = [
  { value: "ZHAL", label: "Semi Finished Material" },
  { value: "ZROH", label: "Raw Material" }
];

export const materialTypesLookup = {
  ZHAL: "Semi Finished Material",
  ZROH: "Raw Material"
};

export const materialTypesName = {
  SemiFinishedMaterial: "ZHAL",
  RawMaterial: "ZROH"
};

export const GALAXY_TAB_A7_BREAKPOINT_W = 1340;
// const GALAXY_TAB_A7_BREAKPOINT_H = 800
export const FOLDER = "Public/";
export const DIR_TO_DOC = "test_uc2/documents/";

export const GET_PROJECT_PATH = "/api/project";
export const STAGE_GATE_PATH = "/api/project/stageGate";
export const TODO_PATH = "/api/todo";
export const MAIN_PIC_PROJECT_PATH = "/mainPICProject";
export const DELETE_MAIN_PIC_PROJECT_PATH = "/mainPICProject/delete";
export const GET_PIC_PROJECT_PATH = "/mainPICProject/assignedPicUser";
export const GET_PLANT_PATH = "/api/checklistRecord/plant";
export const YARD_PATH = "/api/checklistRecord/yard";
export const GET_CLIENT_LIST_PATH = "/api/suggest/clientName";
export const GET_DASHBOARD_EMBED_URL = "/api/quicksight/getDashboardEmbedUrl";
export const PUT_IS_FAVOURITE_PATH = "/api/project/isFavourite";
export const CHECKLIST_SUGGESTION_PATH = "/api/suggest/suggestedChecklist";

// CRM API
export const CRM_OPPORTUNITY_PATH = "/crm/opportunities";
export const CRM_GET_NEXT_ACTION_PATH = "/crm/project-allocations/opportunities";
export const CRM_CONFIG_BUILDING_TYPES_PATH = "/crm/configs/building-types";
export const CRM_APP_ROLES = "/crm/app-roles";
export const CRM_PROJECT_ALLOCATION_ALL_PATH = "/crm/project-allocations/productions";

export const QS_DASHBOARD_PROJECT_OVERVIEW = "8bc75728-6801-4d34-8bc5-41e8df5f5210";
export const QS_DASHBOARD_MOCKUP_DEPLOYMENT_TRACKER = "60e891bb-f9d2-436b-ab20-c066206ecd4e";
export const QS_DASHBOARD_QUALITY_OVERVIEW = "8d1cfe0e-0358-4e5a-898f-a7bd00b5adea";
export const QS_DASHBOARD_QUALITY_ISSUE_REPORT = "b590a98b-a91a-4070-ac6c-08f8d4659a7d";
export const QS_DASHBOARD_EXECUTIVE = "77f161fb-65f5-44bc-b019-bd7ebe179a01";
export const QS_DASHBOARD_PRODUCTION = "af4ceaef-7b03-4610-aafe-04475d5b3ae2"; 
export const CRM_FFORM_PATH = "/crm/f-forms/";
export const CRM_DEPLOY_ALLOCATION_ALL_PATH = "/crm/project-allocations/deployments";
export const CRM_PROJECT_ALLOCATION_PATCH_FACTORY_CAPCITY = "/crm/project-allocations/factory-capacity";
export const CRM_OPPORTUNITY_PATH_COMMON = "/crm/opportunities/common";
export const CRM_CONFIG_CURRENCIES_PATH = "/crm/configs/currencies";
export const CRM_CONFIG_ADD_SUBTYPES_PATH = "/crm/configs/sub-building-types";
export const CRM_CONFIG_DELETE_SUBTYPES_PATH = "/crm/configs/sub-building-types";
export const CRM_CONFIG_EDIT_SUBTYPES_PATH = "/crm/configs/sub-building-types";
export const CRM_OPPORTUNITY_PATH_COMMON_CLIENT_NAME = "/crm/opportunities/common/clientName";
export const CRM_OPPORTUNITY_PATH_COMMON_CONTACT = "/crm/opportunities/common/contact";
export const CRM_ADD_CLIENT = "/crm/clients/add";
export const CRM_ADD_CONTACT = "/crm/contacts/add";
export const CRM_ADD_SERVICE_TYPE = "/crm/opportunities/addServiceType";
export const CRM_ADD_PROCUREMENT_METHOD = "/crm/opportunities/addProcurementMethod";
export const CRM_CHECK_DUP_PROJECT_NAME = "/crm/opportunities/projectName/exists";
export const CRM_VALIDATE_CONTACT_NAME = "/crm/contacts/isValidContactName";
export const CRM_VALIDATE_CLIENT_NAME = "/crm/clients/isValidClientName";
export const POST_OPPORTUNITY_NOTE = "/crm/opportunities/notes/add";
export const UPDATE_OPPORTUNITY_NOTE = "/crm/opportunities/notes/update";
export const DELETE_OPPORTUNITY_NOTE = "/crm/opportunities/notes";
export const GET_OPPORTUNITY_DETAIL = "/crm/opportunities/detail";
export const CRM_UPDATE_PAYMENT_SCHEME = "/crm/opportunities/paymentScheme";
export const CRM_UPDATE_MAIN_PIC = "/crm/opportunities/mainPIC";
export const CRM_UPDATE_KEY_TERMS = "/crm/opportunities/keyTerms";
export const CRM_GET_PROCUREMENT_COMMON = "/crm/opportunities/common/procurementMethod";
export const CRM_GET_NATURE_OF_CLIENT_COMMON = "/crm/opportunities/common/natureOfClient";
export const CRM_GET_LIST_CONVERSION_RATE = "/crm/opportunities/salesForecastAssumption";
export const CRM_ADD_SALES_FORECAST_ASSUMPTION = "/crm/opportunities/salesForecastAssumption";
export const CRM_UPDATE_SALES_FORECAST_ASSUMPTION = "/crm/opportunities/salesForecastAssumption";
export const CRM_DELETE_SALES_FORECAST_ASSUMPTION = "/crm/opportunities/salesForecastAssumption";
export const CRM_GET_TIMELINE_FORECAST_ASSUMPTION = "/crm/opportunities/salesTimeLineAssumption";
export const QS_DASHBOARD_SALES = "7a7daf2d-b9c0-4e85-86be-6f261a76c838";
export const QS_DASHBOARD_KPI_DASHBOARD = "885f8db9-1b89-4009-a804-46d4214eb182";
export const QS_DASHBOARD_LOGISTIC_KPI_DASHBOARD = "4c75960f-9673-48da-99c0-3253a4ce2d9b";
export const QS_PHASING_REPORT= "6bcf36c9-b7c3-4093-9e4a-b749f5354e81";  
export const CRM_GET_LIST_NEXT_ACTION = "/crm/project-allocations/next-actions";
export const CRM_VALIDATE_SERVICE_TYPE = "/crm/project-allocations/opportunities/service-types/exists";
export const CRM_VALIDATE_PROCUREMENT_METHOD = "/crm/project-allocations/opportunities/procurements/exists";
export const CRM_PROJECT_ALLOCATIONS_COMBINED = "/crm/project-allocations";

export const FOLDER_FFORM_FILES = "crm/accountManagement/f-form";
// For UAT: "UAT Inspection Team";
// For PRODUCTION: "PROD Inspection Team";
export const INSPECTION_TEAM_NAME = "UAT Inspection Team";
