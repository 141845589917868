/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:db96cfca-8639-4946-ada6-280741ec6bf1",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_GDlokEpeW",
    "aws_user_pools_web_client_id": "7p8n26mu3ppnioti8b9u3tqr69",
    "oauth": {
        "domain": "tophat-dev.auth.eu-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://uat.tophatmvp.uk/",
        "redirectSignOut": "https://uat.tophatmvp.uk/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "tophatstaticfile183929-dev",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_cloud_logic_custom": [
        {
            "name": "checklistApi",
            "endpoint": "https://0oftvod5ml.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "checklistRecordApi",
            "endpoint": "https://b39auinb69.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "checklistTemplateApi",
            "endpoint": "https://o1gp5i9852.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "entityApi",
            "endpoint": "https://r23hebsxkj.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "projectApi",
            "endpoint": "https://ewh67m2zj8.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "quicksightApi",
            "endpoint": "https://7o11dvubge.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "suggestApi",
            "endpoint": "https://7v5q4wjxkl.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "todoApi",
            "endpoint": "https://cwj763l2u4.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "tophatApi",
            "endpoint": "https://lf9zvk2wz9.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        },
        {
            "name": "crmApi",
            "endpoint": "https://m7s40h7usg.execute-api.eu-west-2.amazonaws.com/dev",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
